import React from 'react';

import { Loader } from '@ui-kit/main-frontend-ui-kit/dist/src/components/Loader';

interface LoadingHOCProps {
	isLoading: boolean;
	width?: number;
	height?: number;
	children?: React.ReactElement<any>;
}
const LoadingHOC: React.FC<LoadingHOCProps> = ({
	isLoading,
	width = 20,
	height = 20,
	children,
}) => {
	return isLoading ? <Loader width={width} height={height} /> : <>{children}</>;
};

export default LoadingHOC;
