import React, { useRef } from 'react';

import useCloseOnClickOutside from '@ui-kit/main-frontend-ui-kit/dist/src/hooks/useCloseOnClickOutside';
import { useLocale } from 'next-intl';

import styles from './UserProfileMenu.module.scss';

import Avatar from '@components/Avatar';
import UiText, { TextType } from '@components/Ui-text';
import { useUserStore } from '@store/user';
import { TRIBUNA_COM_URL } from '@utils/constants';
import { sendAnalytics } from '@utils/helpers';
import { AvatarType } from '@utils/types';

enum ProfileMenuLinksTypes {
	Profile = 'my_profile',
	Edit = 'edit',
	CreatePost = 'create_post',
	SignOut = 'sign_out',
}

export const UserProfileMenu: React.FC = () => {
	const { user, userLogout } = useUserStore(state => state);
	const locale = useLocale();
	const userInfo = user?.info;
	const userDropdownRef = useRef(null);
	const [isUserDropdownOpened, setUserDropdownOpened] = useCloseOnClickOutside({
		elementRef: userDropdownRef,
	});
	const userAvatarUrl = userInfo?.avatar?.url || '';
	const profileMenuLinks = [
		{
			id: ProfileMenuLinksTypes.Profile,
			link: `${TRIBUNA_COM_URL}${locale}/profile/${userInfo?.id}/`,
			text: 'Profile',
		},
		// {
		// 	id: ProfileMenuLinksTypes.Edit,
		// 	link: `/${locale}/cabinet/${userInfo?.id}/`,
		// 	text: t('edit'),
		// },
		// {
		// 	id: ProfileMenuLinksTypes.CreatePost,
		// 	link: `/${locale}/tribuna/create-post/`,
		// 	text: t('new-post'),
		// },
		{
			id: ProfileMenuLinksTypes.SignOut,
			text: 'Sign out',
			action: event => {
				event.preventDefault();
				userLogout();
				setUserDropdownOpened(false);
			},
		},
	];
	const onProfileMenuLinks = (event, item) => {
		if (typeof item.action === 'function') item.action(event);
		event.stopPropagation();
		sendAnalytics({
			category: 'menu',
			name: 'click',
			value: item?.id,
			label: 'profile',
		});
	};

	return (
		<div ref={userDropdownRef}>
			<Avatar
				width={30}
				height={30}
				src={userAvatarUrl}
				type={AvatarType.Person}
				className={styles['user-profile-menu__avatar']}
				onClick={() => setUserDropdownOpened(!isUserDropdownOpened)}
			/>
			{isUserDropdownOpened && (
				<div className={styles['user-profile-menu']}>
					{profileMenuLinks.map(item => (
						<div
							className={styles['user-profile-menu__text-wrapper']}
							key={item.text}
							onClick={event => onProfileMenuLinks(event, item)}
						>
							<UiText
								type={TextType.Subtitle_2}
								link={item.link}
								className={styles['user-profile-menu__text']}
								onClickOnLink
							>
								{item.text}
							</UiText>
						</div>
					))}
				</div>
			)}
		</div>
	);
};
