import React, {
	MouseEventHandler,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { useLocale, useTranslations } from 'next-intl';

const ThemeToggle = dynamic(
	() =>
		import(
			'@ui-kit/main-frontend-ui-kit/dist/src/components/Theme-toggle'
		).then(e => e.ThemeToggle),
	{
		ssr: true,
	},
);
const NoSsr = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/NoSSRHOC').then(
			m => m.NoSsr,
		),
	{
		ssr: false,
	},
);

import styles from './Header.module.scss';

import CloseIcon from '@assets/svg/Close.svg';
import MenuIcon from '@assets/svg/Menu.svg';
import TribunaLogo from '@assets/svg/Tribuna-logo.svg';

import { useScrollDirection } from '@ui-kit/main-frontend-ui-kit/dist/src/hooks/useScrollDirection';
import useUpdateEffect from '@ui-kit/main-frontend-ui-kit/dist/src/hooks/useUpdateEffect';

import DropdownMenu from '@components/Dropdown-menu';
import SearchBarHOC from '@components/SearchBarHOC';
import { useUserStore } from '@store/user';
import {
	BETTING_SPORT_URLS,
	bookieDefRatingUrls,
	casinoDefRatingUrls,
} from '@utils/bookie';
import { TRIBUNA_COM_URL } from '@utils/constants';
import {
	clubsWithValuesForLang,
	getLink,
	getTopTransfers,
	sendAnalytics,
	topLeaguesForLang,
} from '@utils/helpers';
import { locales } from '@utils/navigations_intl';
import { isMobileDeviceMDNCheck } from '@utils/tools';
import { LANG, ScrollDirection } from '@utils/types';

import SignInButton from './SignInBtn';
import { UserProfileMenu } from './User-profile-menu';

const TopSection = dynamic(() => import('./Top-section'), {
	ssr: true,
});

const BannerBonusInMenu = dynamic(
	() => import('@components/Banner-bonus-in-menu'),
	{
		ssr: false,
	},
);

interface IHeaderProps {
	menu: any;
}

export const Header: React.FC<IHeaderProps> = () => {
	const { loginCheck, user } = useUserStore(state => state);

	const t = useTranslations('translations');
	const hasUserLoggedIn = user.hasUserLoggedIn;
	const pathname = usePathname();
	const defaultLocale = 'default';

	const locale = useLocale();

	const currentLocale = locale as LANG;
	const topClubs = clubsWithValuesForLang(currentLocale);
	const topLeagues = topLeaguesForLang({ lang: currentLocale });
	const topTransfers = getTopTransfers({ currentLocale });
	const listOfTabs = [
		'news',
		// 'transfers',
		'live',
		'leagues',
		'teams',
		// 'players',
		'blogs',
		'apps',
		'betting',
		// 'igaming',
	];
	const homeLink =
		currentLocale === LANG.en
			? TRIBUNA_COM_URL
			: `https://${locale}.tribuna.com/`;

	const tabsValues = {
		news: {
			name: 'news',
			isActive: false,
			link: `${TRIBUNA_COM_URL}${currentLocale}/news/`,
			isOpened: false,
		},
		blogs: {
			name: 'blogs',
			isActive: false,
			link: `${TRIBUNA_COM_URL}${currentLocale}/blogs/`,
			isOpened: false,
		},
		transfers: {
			name: 'transfers',
			isActive: false,
			link: `${TRIBUNA_COM_URL}${currentLocale}/other/transfers/`,
			isOpened: false,
			children: topTransfers,
			sectionInfo: {
				name: t('all-transfers'),
				image: '',
				link: `${TRIBUNA_COM_URL}${currentLocale}/other/transfers/`,
				analyticsName: 'transfers',
				showMore: true,
			},
		},
		betting: {
			name: 'betting',
			isActive: false,
			link: `${TRIBUNA_COM_URL}${currentLocale}/${BETTING_SPORT_URLS[currentLocale]}/`,
			isOpened: false,
			children: [
				{
					name: t('online-bookmakers'),
					link: `${TRIBUNA_COM_URL}${currentLocale}/${BETTING_SPORT_URLS[currentLocale]}/${bookieDefRatingUrls?.[currentLocale]}/`,
				},
				{
					name: t('iGaming'),
					link: `${TRIBUNA_COM_URL}${currentLocale}/casino/`,
				},
				{
					name: t('casino-ratings'),
					link: `${TRIBUNA_COM_URL}${currentLocale}/casino/${casinoDefRatingUrls?.[currentLocale]}/`,
				},
			],
		},
		igaming: {
			name: 'iGaming',
			isActive: false,
			link: `${TRIBUNA_COM_URL}${currentLocale}/casino/`,
			isOpened: false,
			children: [
				{
					name: t('casino-ratings'),
					link: `${TRIBUNA_COM_URL}${currentLocale}/casino/${casinoDefRatingUrls?.[currentLocale]}/`,
				},
			],
		},
		apps: {
			name: 'apps',
			isActive: false,
			isOpened: false,
		},
		live: {
			name: 'live',
			isActive: false,
			link: `${TRIBUNA_COM_URL}${currentLocale}/match/`,
			hrefTitle: {
				en: 'Football Scores & Fixtures',
				it: 'Risultati e calendario',
				fr: 'Résultats et Calendrier',
				es: 'Resultados y Calendario',
				de: 'Fußballergebnisse und Spielplan',
			},
			isOpened: false,
		},
		leagues: {
			name: 'leagues',
			isActive: false,
			link: `${TRIBUNA_COM_URL}${currentLocale}/league/`,
			children: topLeagues,
			sectionInfo: {
				name: t('all-tournaments'),
				image: '',
				link: `${TRIBUNA_COM_URL}${currentLocale}/league/`,
				analyticsName: 'leagues',
				showMore: true,
			},
			isOpened: false,
		},
		teams: {
			name: 'teams',
			isActive: false,
			link: `${TRIBUNA_COM_URL}${currentLocale}/clubs/`,
			children: [
				...topClubs.map(item => {
					return {
						...item,
						link: `${TRIBUNA_COM_URL}${getLink({
							lang: LANG[currentLocale],
							alias: item.alias,
						})?.slice(1)}`,
						img:
							item?.img ||
							`https://statistics-logo.tribuna.com/image/team/${item.old_id}`,
					};
				}),
			],
			sectionInfo: {
				name: t('all-teams')?.toUpperCase(),
				image: '',
				link: `${TRIBUNA_COM_URL}${currentLocale}/clubs/`,
				analyticsName: 'teams',
				showMore: true,
			},
			isOpened: false,
		},
		players: {
			name: 'players',
			isActive: false,
			link: `${TRIBUNA_COM_URL}${currentLocale}/persons/`,
			sectionInfo: {
				name: t('all-players'),
				image: '',
				link: `${TRIBUNA_COM_URL}${currentLocale}/persons/`,
				analyticsName: 'players',
				showMore: true,
			},
			children: [
				{
					name: 'Lionel Messi',
					img: 'https://statistics-logo.tribuna.com/image/player/lionel_messi',
					link: `${TRIBUNA_COM_URL}${currentLocale}/persons/lionel-messi/`,
					type: 'player',
					id: 30,
				},
				{
					name: 'Cristiano Ronaldo',
					img: 'https://statistics-logo.tribuna.com/image/player/cristiano_ronaldo',
					link: `${TRIBUNA_COM_URL}${currentLocale}/persons/cristiano-ronaldo/`,
					type: 'player',
					id: 31,
				},
				{
					name: 'Kylian Mbappe',
					img: 'https://statistics-logo.tribuna.com/image/player/kylian_mbappe',
					link: `${TRIBUNA_COM_URL}${currentLocale}/persons/mbappe/`,
					type: 'player',
					id: 32,
				},
				{
					name: 'Luka Modrić',
					img: 'https://statistics-logo.tribuna.com/image/player/luka_modric',
					link: `${TRIBUNA_COM_URL}${currentLocale}/persons/luka-modric/`,
					type: 'player',
					id: 33,
				},
				{
					name: 'Mohamed Salah',
					img: 'https://statistics-logo.tribuna.com/image/player/mohamed_salah',
					link: `${TRIBUNA_COM_URL}${currentLocale}/persons/salah/`,
					type: 'player',
					id: 34,
				},
				{
					name: 'Neymar',
					img: 'https://statistics-logo.tribuna.com/image/player/neymar',
					link: `${TRIBUNA_COM_URL}${currentLocale}/persons/neymar/`,
					type: 'player',
					id: 35,
				},
				{
					name: 'Eden Hazard',
					img: 'https://statistics-logo.tribuna.com/image/player/eden_hazard',
					link: `${TRIBUNA_COM_URL}${currentLocale}/persons/eden-hazard/`,
					type: 'player',
					id: 36,
				},
			],
			isOpened: false,
		},
	};

	const [isOpened, setIsOpened] = useState(false);
	const activeSubsection = useRef<string>(null);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => setIsMounted(true), []);

	const onMenuIconClick: MouseEventHandler<HTMLElement> = () => {
		const newState = !isOpened;
		setIsOpened(newState);

		// if (isOpened) {
		// 	changeBookmakerGiftPopup(BookmakerGiftPopupStatus.Close);
		// }

		if (newState) {
			sendAnalytics({
				category: 'menu',
				name: 'view',
				label: 'sidebar',
			});
		}
	};

	const onSubsectionClick: MouseEventHandler<HTMLInputElement> = evt => {
		const target = evt.target as HTMLInputElement;
		if (target.value === activeSubsection.current) {
			activeSubsection.current = null;
			target.checked = false;
		} else {
			activeSubsection.current = target.value;
		}
	};

	useUpdateEffect(() => {
		loginCheck();
	}, [hasUserLoggedIn, isMounted]);

	useUpdateEffect(() => {
		React.startTransition(() => {
			setIsOpened(false);
		});
	}, [pathname]);

	const onUserProfileMenuClick = () => {
		sendAnalytics({
			category: 'menu',
			name: 'view',
			label: 'profile',
		});
	};

	const computeDropdownItems = () => {
		const dropdownLangs = [];
		locales.forEach(lang => {
			if (lang === 'default') {
				return;
			}
			dropdownLangs.push({
				text: lang.toUpperCase(),
				// TODO: когда будут переводы, закинуть перевод языков
				link: `${TRIBUNA_COM_URL}${lang}/`,
			});
		});
		return dropdownLangs;
	};
	const memoizedDropdownValues = useMemo(
		() => computeDropdownItems(),
		[locales],
	);

	const scrollDirection = useScrollDirection({ threshold: 100 });
	const shouldHideBottomSection =
		scrollDirection === ScrollDirection.Down && !isMobileDeviceMDNCheck();

	useEffect(() => {
		const body = document.getElementsByTagName('body')[0];

		body.setAttribute(
			'data-header-size',
			shouldHideBottomSection ? 'one-line' : 'two-line',
		);
	}, [shouldHideBottomSection]);
	return (
		<div className={styles['header']}>
			<div className={styles['header__top']}>
				<div className={styles['header__menu-icon']} onClick={onMenuIconClick}>
					{isOpened ? (
						<CloseIcon width={20} height={20} />
					) : (
						<MenuIcon width={20} height={20} />
					)}
				</div>
				<div className={styles['header__tribuna-logo']}>
					<a href={homeLink} className={styles['header__tribuna-logo__logo']}>
						<TribunaLogo
							className={styles['header__tribuna-logo__text']}
							width={157}
							height={26}
						/>
					</a>
					<img
						src={'/seo_logo.svg'}
						className={styles['header__tribuna-logo__seo']}
						width={157}
						height={112}
					/>
				</div>

				<div
					className={clsx(styles['header__top-sections'], {
						[styles['header__top-sections--closed']]: !isOpened,
					})}
				>
					{isOpened && <SearchBarHOC hideOnDesktop isWidget />}
					{listOfTabs?.map((sectionID, index) => (
						<React.Fragment key={`menu-main-section-${index}`}>
							<TopSection
								section={tabsValues[sectionID]}
								onSubsectionClick={onSubsectionClick}
								key={`main-section-${index}`}
							/>
						</React.Fragment>
					))}
				</div>
				<NoSsr hideFromBot>
					<div className={styles['header__menu-bonus']}>
						<BannerBonusInMenu />
					</div>
				</NoSsr>

				<div
					className={clsx(styles['header__empty-line'], {
						[styles['header__empty-line--closed']]: !isOpened,
					})}
				/>
				<div
					className={clsx(styles['header__right-buttons-wrapper'], {
						[styles['header__right-buttons-wrapper--closed']]: !isOpened,
					})}
				>
					<div
						className={clsx(styles['header__dropdown-wrapper'], {
							[styles['header__dropdown-wrapper--closed']]: !isOpened,
						})}
					>
						<DropdownMenu
							dropdownItems={memoizedDropdownValues}
							chosenItem={{
								text: locale.toUpperCase() || defaultLocale.toUpperCase(),
							}}
							onItemClick={item => {
								sendAnalytics({
									category: 'local',
									name: 'click',
									label: 'main',
									value: item.text.toLowerCase(),
								});
							}}
							isItHeaderDropdown
						/>
					</div>
					<div
						className={clsx(styles['header__login-button-wrapper'], {
							[styles['header__login-button-wrapper--closed']]: !isOpened,
						})}
						onClick={onUserProfileMenuClick}
					>
						{hasUserLoggedIn ? <UserProfileMenu /> : <SignInButton />}
					</div>
				</div>
				<div
					className={clsx(styles['header__theme-button-wrapper'], {
						[styles['header__theme-button-wrapper--closed']]: !isOpened,
					})}
				>
					{isOpened && <ThemeToggle hideOnDesktop />}
				</div>
			</div>
			<div
				className={clsx(styles['header__bottom'], {
					[styles['header__bottom--closed']]: !isOpened,
					[styles['header__bottom--scroll-down']]: shouldHideBottomSection,
				})}
			>
				<ThemeToggle hideOnMobile />
				<SearchBarHOC hideOnMobile isWidget />
			</div>
		</div>
	);
};

export default Header;
