import { useLazyQuery } from '@apollo/client';
import dynamic from 'next/dynamic';
import { useLocale } from 'next-intl';

import appQueries from '@store/app/queries';
import { isMobileDeviceMDNCheck } from '@utils/tools';
import { LANG, PROJECTS } from '@utils/types';

const SearchBar = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/Search').then(
			m => m.SearchBar,
		),
	{
		ssr: true,
	},
);

const SearchBarHOC = ({
	hideOnMobile = false,
	hideOnDesktop = false,
	isWidget = false,
}) => {
	const isItMobile = isMobileDeviceMDNCheck();
	const locale = useLocale();

	const [getSearchQueryResults] = useLazyQuery(appQueries.searchQuery, {
		errorPolicy: 'all',
	});

	return (
		<SearchBar
			hideOnMobile={hideOnMobile}
			isWidget={isWidget}
			hideOnDesktop={hideOnDesktop}
			getSearchQueryResults={getSearchQueryResults}
			isItMobile={isItMobile}
			locale={locale as LANG}
			project={PROJECTS.INT}
		/>
	);
};

export default SearchBarHOC;
