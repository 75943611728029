import {
	TextType,
	UiText,
} from '@ui-kit/main-frontend-ui-kit/dist/src/components/Ui-text';

// import '@ui-kit/main-frontend-ui-kit/dist/src/components/Ui-text/index.css';

export { TextType, UiText };

export default UiText;
