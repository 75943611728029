import React, { type JSX } from 'react';

// import { useAmp } from 'next/amp';

import styles from './Avatar.module.scss';

import AvatarIcon from '@assets/svg/avatar.svg';
import FlagIcon from '@assets/svg/Flag.svg';
import PlayerIcon from '@assets/svg/Person.svg';
import ClubIcon from '@assets/svg/TeamLogoIcon.svg';
import TournamentIcon from '@assets/svg/Tournament.svg';
import ImageComponent, { IImageProps } from '@components/Image';
import { AvatarType } from '@utils/types';

interface IAvatarProps extends Partial<IImageProps> {
	type?: AvatarType;
	onClick?: () => void;
	link?: string;
	dataTest?: string;
}

const Avatar: React.FC<IAvatarProps> = ({
	src,
	type = AvatarType.Other,
	width = 32,
	height = 32,
	className = '',
	alt = '',
	priority,
	onClick,
	link,
	dataTest,
}) => {
	let Image: JSX.Element;
	const props = {
		style: {
			'--width': `${width}px`,
			'--height': `${height}px`,
		} as React.CSSProperties,
		'data-test': dataTest,
	};

	// const isAmp = useAmp();

	if (src) {
		Image = (
			<ImageComponent
				src={src}
				className={className}
				alt={alt}
				priority={priority}
				width={width}
				height={height}
			/>
		);
	} else {
		switch (type) {
			case AvatarType.Person:
				Image = <PlayerIcon width={width} height={height} color={'#C2C2C2'} />;
				break;
			case AvatarType.User:
				Image = <AvatarIcon width={width} height={width} />;
				break;
			case AvatarType.CountryFlag:
				Image = <FlagIcon width={15} height={10} />;
				break;
			case AvatarType.Tournament:
				// for the future maybe better to ask designers to change icon size
				Image = (
					<TournamentIcon
						width={+width - (+width * 25) / 100}
						height={height}
					/>
				);
				break;
			default:
				Image = <ClubIcon width={width} height={height} />;
		}
	}

	return (
		<>
			{link ? (
				<a
					href={link}
					className={`${className} ${styles['avatar-wrapper']} `}
					{...props}
					onClick={onClick}
				>
					{Image}
				</a>
			) : (
				<div
					className={`${className} ${styles['avatar-wrapper']} `}
					{...props}
					onClick={onClick}
				>
					{Image}
				</div>
			)}
			{/* {isAmp ? (
				<style jsx>{`
					${`}${TagThumbnailCSS}{`}
				`}</style>
			) : (
				<style jsx scoped>{`
					${`${TagThumbnailCSS}`}
				`}</style>
			)} */}
		</>
	);
};

export default Avatar;
