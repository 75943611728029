import { gql } from '@apollo/client';

import { LOCALIZED_STRING_FRAGMENT } from '@store/fragments';

const EXTRA_TEAM_FRAGMENT = gql`
	fragment tagTeamExtra on TagTeamExtra {
		interfaceTitle {
			...localizedString
		}
		type
	}
`;

const EXTRA_OTHER_FRAGMENT = gql`
	fragment tagOtherExtra on TagOtherExtra {
		interfaceTitle {
			...localizedString
		}
	}
`;

const EXTRA_PERSON_FRAGMENT = gql`
	fragment tagPersonExtra on TagPersonExtra {
		firstName {
			...localizedString
		}
		lastName {
			...localizedString
		}
	}
`;
const EXTRA_TOURNAMENT_FRAGMENT = gql`
	fragment tagTournamentExtra on TagTournamentExtra {
		interfaceTitle {
			...localizedString
		}
	}
`;

export default {
	appInfo: gql`
		query appInfo {
			appInfo {
				id
				alias
				name
				languages
				manifestUrl
				webTitle
				via
			}
		}
	`,
	searchQuery: gql`
		${LOCALIZED_STRING_FRAGMENT}
		${EXTRA_TOURNAMENT_FRAGMENT}
		${EXTRA_PERSON_FRAGMENT}
		${EXTRA_TEAM_FRAGMENT}
		${EXTRA_OTHER_FRAGMENT}
		query searchQuery($searchPattern: String!) {
			searchQueries {
				search(input: { pattern: $searchPattern }) {
					objectPages {
						objects {
							... on Tag {
								id
								hru
								type
								extra {
									__typename
									... on TagTournamentExtra {
										...tagTournamentExtra
									}
									... on TagPersonExtra {
										...tagPersonExtra
									}
									... on TagTeamExtra {
										...tagTeamExtra
									}
									... on TagOtherExtra {
										...tagOtherExtra
									}
								}
								title {
									...localizedString
								}
								logo {
									url
								}
								statObject {
									... on statTeam {
										id
										name
										type
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
										}
									}
									... on statPlayer {
										id
										name
										firstName
										lastName
										coalesceName(names: ["lineupName", "lastName", "name"])
										avatar {
											main
										}
										currentClub {
											id
											tag {
												id
												hru
												type
												title {
													...localizedString
												}
												logo {
													url
												}
												sport {
													path
												}
												extra {
													...tagTeamExtra
												}
											}
											name
											logo {
												main
											}
										}
									}
									... on statTournament {
										id
										name
										logo {
											main
										}
										tag {
											type
											id
											logo {
												url
											}
											extra {
												...tagTournamentExtra
											}
										}
									}
								}
								sport {
									id
									name {
										...localizedString
									}
									path
								}
							}
						}
					}
				}
			}
		}
	`,
};
