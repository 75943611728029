import React, { useState } from 'react';

import {
	ButtonSize,
	ButtonType,
	UiButton,
} from '@ui-kit/main-frontend-ui-kit/dist/src/components/Ui-button';
import { useTranslations } from 'next-intl';

import styles from './Header.module.scss';

import LoadingHOC from '@components/LoadingHOC/LoadingHOC';
import { useUserStore } from '@store/user';

const SignInButton: React.FC = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { openLoginModal } = useUserStore(state => state);
	const t = useTranslations('translations');

	return (
		<LoadingHOC isLoading={isLoading}>
			<UiButton
				className={styles['header__login-button']}
				type={ButtonType.Outlined}
				size={ButtonSize.Small}
				onClick={() => {
					setIsLoading(true);
					openLoginModal();
					setIsLoading(false);
				}}
			>
				{t('sign-in')}
			</UiButton>
		</LoadingHOC>
	);
};

export default SignInButton;
